<template>
  <!-- 列表下拉 -->
  <div class="content">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :immediate-check="false"
        offset="300"
        error-text="请求失败，点击重新加载"
        :finished-text="list.length > 0 ? '没有更多了' : ''"
        @load="onBottomData"
      >
        <div v-if="list.length">
          <div v-for="(item, index) in list" :key="index" class="gas-station">
            <div class="gas-station-info" @click="goDetails(item)">
              <div class="station-img">
                <img :src="item.logoSmall" alt="">
              </div>
              <div class="station-info">
                <div class="name-location">
                  <div class="station-name">
                    {{ item.title }}
                  </div>
                </div>
                <div class="gas-price-container">
                  <div class="gas-price">
                    <span>¥</span><span class="price">{{ item.price }}</span>/升/{{ item.oil }}
                  </div>
                </div>
                <!-- 油站标签 -->
                <div class="station-tags">
                  <div class="reduction">
                    <div class="tag-icon" />
                    <div class="discount">已降 ¥ {{ item.discount }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="station-address">
              <div class="address-content">
                <div class="location-icon" />
                <div class="address">{{ item.address }}</div>
              </div>
              <div class="location-address-button" @click="navigation(item)">
                <img src="@/assets/images/refueling/navigation-icon-btn.png" alt="">
                <span>{{ item.distance }}</span>
              </div>
            </div>
            <div v-if="item.tagList && item.tagList.length > 0" class="preferential">
              <div v-for="(i, j) in item.tagList" :key="j" class="preferential-li">
                <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-08-02/15/yuelvhuiNY5ceBP8bk1627888310.png" alt="">
                <span>{{ i.indexDesc }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!loading">
          <van-empty description="暂无内容" />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Vue from 'vue'
import { Empty, ActionSheet, DropdownItem, DropdownMenu, Icon, List, Popup, PullRefresh, Sticky, Tab, Tabs, Toast } from 'vant'
import { isWeChat, isWeChatApplet } from '@/utils/common/validate'

Vue.use(Toast)
  .use(Empty)
  .use(Popup)
  .use(Icon)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Sticky)
  .use(List)
  .use(PullRefresh)
  .use(ActionSheet)
  .use(Tab)
  .use(Tabs)

export default {
  name: 'RefuelingStationList',
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
      require: true
    },
    finished: {
      type: Boolean,
      default() {
        return false
      }
    },
    isLoading: {
      type: Boolean,
      default() {
        return false
      }
    },
    isRefreshing: {
      type: Boolean,
      default() {
        return false
      }
    },
    page: {
      type: Number,
      default() {
        return 1
      }
    },
    error: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      loading: false,
      refreshing: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    isLoading() {
      this.loading = this.isLoading
    },
    isRefreshing() {
      this.refreshing = this.isRefreshing
    },
    loading() {
      this.$emit('update:isLoading', this.loading)
    },
    refreshing() {
      this.$emit('update:isRefreshing', this.refreshing)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  // 方法集合
  methods: {
    // 导航
    navigation(item) {
      if (isWeChat() && !isWeChatApplet()) {
        window.location.href = 'http://apis.map.qq.com/uri/v1/marker?marker=coord:' + Number(item.lat) + ',' + Number(item.lng) + ';title:' + item.title + ';addr:' + item.address
      } else if (isWeChatApplet()) {
        wx.openLocation({
          latitude: Number(item.lat), // 纬度，浮点数，范围为90 ~ -90
          longitude: Number(item.lng), // 经度，浮点数，范围为180 ~ -180。
          name: item.title, // 位置名
          address: item.address, // 地址详情说明
          scale: 20 // 地图缩放级别,整型值,范围从1~28。默认为最大
        })
      }
    },
    // 帮助客服
    help() {
      this.$router.push({
        path: '/comeOnHelp'
      })
    },
    // 请求失败，点击重新加载
    onRefresh() {
      this.$emit('update:page', 1)
      this.$emit('changePage')
    },
    // 触底请求
    onBottomData() {
      const page = this.page + 1
      this.$emit('update:page', page)
      this.$emit('changePage')
    },
    // 进详情
    goDetails(item) {
      this.$emit('showDistancePopUp', item)
    }
  }
}
</script>

<style lang="less" scoped>
/*油站列表*/
.content {
  position: relative;
  /*top: 98px;*/
  padding: 10px 10px 74px 10px;
  background-color: #f8f8f8;
  min-height: 100vh;

  .kong {
    padding-top: 50%;
    color: #666666;
  }

  .gas-station {
    background-color: #ffffff;
    border-radius: 7px;
    padding: 13px 10px;
    margin-bottom: 10px;

    .gas-station-info {
      display: flex;
      margin-bottom: 18px;

      .station-img {
        width: 77px;
        height: 77px;
        background-color: #999999;
        border-radius: 5px;
        overflow: hidden;
        color: #fff;
        margin-right: 20px;

        & img {
          width: 100%;
          height: 100%;
          border-radius: 3px;
        }
      }

      .station-info {
        width: calc(100% - 97px);
        .name-location {
          display: flex;
          align-items: flex-start;
          margin-bottom: 9px;
          .station-name {
            flex: auto;
            color: #333333;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .station-distance {
            width: auto;
            white-space: nowrap;
            text-align: right;
            color: #666666;
            font-size: 12px;
            line-height: 14px;
            margin-left: 5px;
          }
        }
        .station-tags {
          padding: 0;
          width: 100%;
          text-align: left;

          .reduction {
            width: auto;
            display: inline-flex;
            height: 18px;
            line-height: 18px;
            border: 1px solid #f7263c;
            border-radius: 3px;
            text-align: left;
            .discount {
              color: #f7263c;
              display: inline-block;
              line-height: 18px!important;
              height: 18px!important;
              font-size: 11px;
              padding: 0 3px;
            }

            .tag-icon {
              width: 14px;
              height: 100%;
              background-color: #f7263c;
              color: #fff;
              display: inline-block;
              background-image: url("~@/assets/images/weizhi_3.png");
              background-repeat: no-repeat;
              background-position: 3px 4px;
              background-size: 7px 9px;
            }
          }
        }

        .gas-price-container {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;
          .gas-price {
            color: #f7263c;
            display: inline-block;
            font-family: PingFangSC, PingFangSC-Medium;
            vertical-align: baseline;
            margin-right: 7px;

            span:nth-child(1) {
              font-size: 12px;
              margin-right: 4px;
            }

            span:nth-child(2) {
              font-size: 22px;
              font-weight: 600;
            }
          }
          .gas-int-price {
            color: #999999;
            text-decoration: line-through;
            font-size: 11px;
            vertical-align: baseline;
          }
        }
      }
    }

    .station-address {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .location-address-button {
        width: 71px;
        height: 30px;
        line-height: 30px;
        margin-left: 20px;
        background-color: #f7263c;
        color: #fff;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
      }

      .address-content {
        display: flex;
        align-items: center;
        text-align: left;
        width: calc(100% - 105px);
        color: #999999;
        font-size: 12px;
        line-height: 25px;
        .address {
          flex: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .location-icon {
          width: 20px;
          min-width: 20px;
          height: 22px;
          line-height: 22px;
          background-image: url("~@/assets/images/refueling/address-icon.png");
          background-repeat: no-repeat;
          background-size: 50% 60%;
          background-position: 50% 45%;
        }
      }
    }

    /* 优惠 */
    .preferential {
      text-align: left;
      padding: 0 5px 0;
      margin-top: 10px;
      border-top: 1px solid #eeeeee;
      .preferential-li {
        margin-top: 10px;
        img {
          width: 23px;
          height: 23px;
          display: inline-block;
          margin-right: 10px;
          transform: translateY(-1px);
          vertical-align: middle;
        }
        span {
          width: calc(100% - 33px);
          display: inline-block;
          vertical-align: middle;
          font-size: 13px;
          color: #666666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .confir_box {
    // padding: 60px;
    background-color: #ffffff;
    position: relative;

    .confir_clone {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .confir_box {
      padding: 40px 35px 20px;

      ::v-deep .van-popup {
        width: 70%;
      }

      .confir_txt1 {
        color: #f7263c;
        font-size: 13px;
        margin-top: 10px;
      }

      .confir_txt2 {
        color: #999999;
        font-size: 12px;
        margin: 10px 0;
      }

      .confir_txt3 {
        display: flex;
        align-items: center;

        & > span:nth-child(1) {
          flex: 6;

          & > img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }

        & > span:nth-child(2) {
          flex: 18;
          color: #333333;
          font-size: 13px;
          margin-left: 10px;
          text-align: left;
        }
      }
    }

    .ok_box {
      display: flex;
      align-items: center;

      & > div {
        padding: 15px;
      }

      & > div:nth-child(1) {
        flex: 12;
        color: #333333;
        background-color: #e9e9ea;
      }

      & > div:nth-child(2) {
        flex: 12;
        background-color: #f7263c;
        color: #fff;
      }
    }
  }
}

/deep/ .van-dropdown-menu {
  border-right: 7px;
}

/deep/ .van-dropdown-menu__bar {
  border-radius: 7px;
}
</style>
<style>
.van-popup--center {
  width: 85% !important;
}
</style>
