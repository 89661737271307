<template>
  <van-swipe class="my-swipe" :autoplay="0" indicator-color="white" :show-indicators="false">
    <van-swipe-item v-for="(image, index) in banner" :key="index" @click="redirectUrl(image)">
      <van-image style="width: 100%;height: 100%;" :src="image.url" />
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import Vue from 'vue'
import { Swipe, SwipeItem, Lazyload, Image } from 'vant'
Vue.use(Swipe).use(SwipeItem).use(Lazyload).use(Image)

export default {
  name: 'FuelBanner',
  props: { banner: { type: null, default: null }},
  methods: {
    redirectUrl(image) {
      if (image.redirectType === 39) {
        this.$router.push({
          path: '/acthds',
          query: { id: image.id }
        })
      } else if (image.redirectType === 3) {
        const obj = {
          product_type: 1,
          goodId: image.redirectParams.id,
          skuId: image.redirectParams.skuId
        }
        this.$store.dispatch('godetail', obj)
      } else if (image.redirectUrl) {
        window.location.href = image.redirectUrl
      }
    }
  }
}
</script>

<style lang="less" scoped>
.my-swipe{
  border-radius: 2px;
  height: 90px;
  overflow: hidden;
  margin-top: 10px;
}
</style>
