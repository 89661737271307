<template>
  <div class="fuel-sort-tab-tab">
    <van-dropdown-menu>
      <van-dropdown-item v-model="rule" :options="(sortsList)" />
      <van-dropdown-item ref="item" title="筛选">
        <div slot="title">
          <div class="choose-fuel-name">{{ chooseFuelName }}</div>
          <div v-if="chooseFuelPriceOfficial" class="choose-fuel-now-standard-price">今日国标价：￥{{ chooseFuelPriceOfficial }}/L</div>
        </div>
        <div class="sort-container">
          <div v-for="(fuel, index) in fuelNumber" :key="'sort-' + index" class="sort-li">
            <div class="sort-title">{{ fuel.name }}</div>
            <div class="sort-items">
              <div
                v-for="(item, j) in fuel.list"
                :key="`item-${j}-${index}`"
                class="sort-item"
                :class="{
                  'choose': active === 0 ? oilId === item.oilId : oilId === item.id
                }"
                @click="handleChooseGasId(item)"
              >
                <div class="item-label">{{ item.name }}</div>
                <div v-if="item.priceOfficial" class="item-tip">国标价￥{{ item.priceOfficial }}/L</div>
              </div>
            </div>
          </div>
        </div>
      </van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>

<script>
import { DropdownMenu as VanDropdownMenu, DropdownItem as VanDropdownItem } from 'vant'

export default {
  name: 'FuelSortTab',
  components: {
    VanDropdownMenu,
    VanDropdownItem
  },
  props: {
    oilId: {
      type: null,
      default: null
    },
    oilType: {
      type: null,
      default: null,
      deep: true
    },
    sort: {
      type: null,
      default: null
    },
    sorts: {
      type: null,
      default: null
    },
    active: {
      type: null,
      default: null
    },
    oilName: {
      type: null,
      default: null
    },
    fuelNumber: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      rule: 1,
      chooseFuelInfo: {}
    }
  },
  computed: {
    sortsList() {
      return this.sorts.map(i => ({ ...i, text: i.name, value: i.id }))
    },
    // 选中的名称信息
    chooseFuelName() {
      const { oilId, active, chooseFuelInfo } = this

      if (oilId) {
        const { name } = chooseFuelInfo
        if (active === 0) {
          return `${oilId}号油`
        }
        if (active === 1 && name) {
          return `${name.replaceAll('#', '')}号油`
        }
      }
      return '请选择油号'
    },
    // 选中的名称信息
    chooseFuelPriceOfficial() {
      return this.chooseFuelInfo.priceOfficial
    }
  },
  watch: {
    sort() {
      this.rule = this.sort
    },
    rule() {
      this.$emit('update:sort', this.rule)
      this.$emit('onSortChange')
    },
    oilId() {
      this.chooseFuelInfo = this.handleChooseInfo()
      this.$nextTick(() => {
        const { chooseFuelInfo } = this
        if (chooseFuelInfo) {
          const { name } = chooseFuelInfo
          this.$emit('update:oilName', name)
        }
      })
    }
  },
  created() {
    if (this.oilId) {
      this.chooseFuelInfo = this.handleChooseInfo()
    }
  },
  methods: {
    /**
     * 处理选中的信息
     * 2021-08-15 16:03:09
     * @author SnowRock
     */
    handleChooseInfo() {
      const { oilId, fuelNumber, active } = this
      if (fuelNumber && fuelNumber.length > 0 && oilId) {
        const info = fuelNumber.reduce((total, i) => {
          if (!total) {
            total = []
          }
          return total.concat(i.list)
        }, 0).filter(i => {
          if (active === 0) {
            return i.oilId === oilId
          }
          if (active === 1) {
            return i.id === oilId
          }
        })[0]
        if (info) {
          this.$forceUpdate()
          this.$emit('update:oilName', info.name)
          return info
        }
      }
      return {}
    },
    /**
     * 处理选择油号
     * 2021-08-13 15:18:59
     * @author SnowRock
     */
    handleChooseGasId(item) {
      const { active } = this
      const { oilId, id } = item
      if (active === 0) {
        this.$emit('update:oilId', oilId)
      }
      if (active === 1) {
        this.$emit('update:oilId', id)
      }
      this.chooseFuelInfo = item
      this.$nextTick(() => {
        this.$emit('update:oilType', this.chooseFuelInfo.type)
        this.$emit('onSortChange')
      })

      this.$forceUpdate()
      this.$refs.item.toggle()
    }
  }
}
</script>

<style lang="less" scoped>
.choose-fuel-name {
  font-size: 13px;
  text-align: center;
  line-height: 18px;
  font-family: PingFangSC, PingFangSC-Regular,sans-serif;
  font-weight: 400;
}
.choose-fuel-now-standard-price {
  font-size: 10px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  line-height: 14px;
  margin-top: 2px;
}

.fuel-sort-tab-tab {
  padding: 0 12px;
  margin-top: 10px;
  ::v-deep .van-dropdown-item__content {
    border-radius: 0 0 6px 6px;
  }
  ::v-deep .van-ellipsis {
    font-size: 13px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
  }
  ::v-deep .van-dropdown-menu__bar {
    border-radius: 6px 6px;
    box-shadow: none;
  }
  .sort-container {
    padding-top: 20px;
    padding-bottom: 24px;

    .sort-li {
      padding: 0 12px;
      font-family: PingFangSC, PingFangSC-Regular,sans-serif;
      .sort-title {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
      }
      .sort-items {
        text-align: left;
        .sort-item {
          width: calc((100% / 3) - 8px);
          max-width: 109px;
          text-align: center;
          display: inline-block;
          box-sizing: border-box;
          color: #333333;
          background: #f8f8f8;
          padding: 8px 0;
          border: 1px solid #f8f8f8;
          border-radius: 6px;
          margin-right: 12px;
          margin-bottom: 10px;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
          &.choose {
            color: #F7263C;
            background: #FFF5F6;
            border: 1px solid #F7263C;

            .item-tip {
              color: #F7263C;
            }
          }
          .item-label {
            font-size: 14px;
            line-height: 20px;
          }
          .item-tip {
            color: #999999;
            font-size: 10px;
            line-height: 14px;
            margin-top: 1px;
          }
        }
      }
    }
  }
}
</style>
