<template>
  <div class="fuel-activity-type-container">
    <div class="tabs-container">
      <van-tabs v-model="tabActive" color="#F7263C" title-active-color="#F7263C" line-width="23px" @click="tabClick">
        <van-tab title="小桔加油" />
        <van-tab title="团油加油" />
      </van-tabs>
      <!-- 活动标签列表 -->
      <div class="activity-tags-container">
        <div class="tags-container">
          <div
            v-for="(i, j) in query"
            :key="'tag-' + j"
            class="tag"
            :class="{
              'choose': type === i.value
            }"
            @click="handleChooseType(i)"
          >{{ i.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tab as VanTab, Tabs as VanTabs } from 'vant'

export default {
  name: 'FuelActivityTypeTab',
  components: {
    VanTab, VanTabs
  },
  props: {
    query: {
      type: null,
      default: null
    },
    type: {
      type: null,
      default: null
    },
    active: {
      type: null,
      default: null,
      deep: true
    }
  },
  data() {
    return {
      tabActive: this.active,
      tabTitle: ''
    }
  },
  watch: {
    active() {
      this.tabActive = this.active
    },
    tabActive() {
      this.$emit('update:active', this.tabActive)
    }
  },
  methods: {
    /**
     * 处理选择tab
     * 2021-08-13 16:17:49
     * @author SnowRock
     */
    handleChooseType({ value }) {
      this.$emit('update:type', value)
      this.$emit('onSortChange')
    },
    /**
     * 处理选择tab
     * 2021-08-13 16:17:49
     * @author SnowRock
     */
    tabClick(name, title) {
      this.$nextTick(() => {
        if (this.tabTitle !== title) {
          this.tabTitle = title
          this.$emit('handleTabChange')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.tabs-container {
  background-color: #fff;
  text-align: left;

  .activity-tags-container {
    width: 100%;
    overflow-x: auto;
    padding: 12px 15px;

    .tags-container {
      width: auto;
      white-space: nowrap;

      .tag {
        color: #999999;
        background: #f5f5f5;
        padding: 5px 9px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular, sans-serif;
        font-weight: 400;
        border-radius: 5px;
        display: inline-block;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }

        &.choose {
          color: #fff;
          background: linear-gradient(90deg,#fb6474, #f7263c);
        }
      }
    }
  }
}
</style>
