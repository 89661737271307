<template>
  <!-- 加油列表 -->
  <div class="app-container">
    <!--   下载APP提示 -->
    <OpenAppHead v-if="isShowTitle" :options="options" @isclose="isClose" />
    <!-- tab筛选  -->
    <FuelActivityTypeTab
      v-if="isShowTitle"
      ref="FuelActivityTypeTab"
      :query="query"
      :type.sync="listQuery.type"
      :active.sync="active"
      @onSortChange="handleFilter"
      @handleTabChange="handleTabChange"
    />
    <!-- 加油banner -->
    <div class="banner-container">
      <FuelBanner :banner="banner" />
    </div>
    <!--<div class="tips-container">
      <div class="tips">油站时时调价，以支付时价格为准</div>
      <div class="help">帮助&客服</div>
    </div>-->
    <!-- 加油筛选 -->
    <FuelSortTab
      v-if="fuelNumber.length > 0"
      :oil-id.sync="listQuery.oilId"
      :oil-type.sync="listQuery.oilType"
      :oil-name.sync="listQuery.oilName"
      :sort.sync="listQuery.sort"
      :sorts="sorts"
      :active="active"
      :fuel-number="fuelNumber"
      @onSortChange="handleFilter"
    />
    <RefuelingStationList
      :list="list"
      :is-loading.sync="isLoading"
      :is-refreshing.sync="isRefreshing"
      :finished.sync="isFinished"
      :page.sync="listQuery.page"
      :active="active"
      @showDistancePopUp="handleNavStationDetail"
      @changePage="initInfo"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import FuelActivityTypeTab from '@/views/localLife/refueling/list/components/FuelActivityTypeTab'
import FuelSortTab from '@/views/localLife/refueling/list/components/FuelSortTab'
import { fetchGroupRefuelingList, getXJComeOnList } from '@/services/comeOn'
import { Toast } from 'vant'
import OpenAppHead from '@/component/GoodsDetail/openApp'
import RefuelingStationList from '@/views/localLife/refueling/list/components/RefuelingStationList'
import FuelBanner from '@/views/localLife/refueling/list/components/FuelBanner'
import { fetchBanner } from '@/services/api'
import { isWeChat, isWeChatApplet } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'
import { getusernew } from '@/services/userApi'
import { setLocal } from '@/utils/common/utils'
import { initAMap } from '@/utils/a-map'
import store from '@/stores'

export default {
  name: 'RefuelingList',
  components: { OpenAppHead, FuelBanner, RefuelingStationList, FuelSortTab, FuelActivityTypeTab },
  data() {
    return {
      isShowTitle: !isWeChatApplet(),
      topHeight: '100px',
      topclose: true,
      isWxStyle: false,
      active: 0, // tab 栏目
      listQuery: {
        oilId: '92',
        oilName: '92#',
        sort: 1,
        type: 0,
        oilType: '1',
        lng: '116.52056749131944',
        lat: '39.904080132378475',
        page: 1
      },
      list: [],
      isLoading: false, // 是否打开loading
      isRefreshing: false, // 是否打开loading
      isFinished: false, // 是否结束
      fuelNumber: [],
      query: [],
      banner: [], // 轮播图
      sorts: []
    }
  },
  computed: {
    options() {
      const obj = {
        showtig: true
      }
      const info = {
        product_type: 61
      }
      obj.info = info
      return obj
    },
    refuelingType() {
      const { active } = this
      switch (active) {
        case 0: return 'xiaoju'
        case 1: return 'group'
        default: return ''
      }
    }
  },
  async created() {
    this.isWxStyle = isWeChat()
    if (this.isWxStyle) { // 微信
      this.topHeight = '93px'
    } else {
      this.topHeight = '40px'
    }
    await this.getUserInfo()
    initAMap(this.initLocation)
    this.handleShare()
  },
  methods: {

    // 初始化定位信息
    initLocation() {
      store.commit('changeShowLoading', true)
      const vm = this
      new AMap.Map('container', {
        resizeEnable: true
      })
      AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: 'RB'
        })

        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete)
        AMap.event.addListener(geolocation, 'error', onError)

        function onComplete(data) {
          // data是具体的定位信息
          console.log('定位成功', data)
          const { position, addressComponent } = data
          localStorage.setItem('longitude', position.lng)
          localStorage.setItem('latitude', position.lat)
          localStorage.setItem('city', addressComponent.city)
          localStorage.setItem('district', addressComponent.district)
          vm.listQuery.lat = position.lat
          vm.listQuery.lng = position.lng
          vm.initInfo()// 油站列表
          vm.getBanner()
        }

        function onError(data) {
          // 定位出错
          console.log('定位出错', data)
          // eslint-disable-next-line no-undef
          $.ajax({
            type: 'get',
            url: 'https://apis.map.qq.com/ws/location/v1/ip',
            data: {
              key: 'UEUBZ-FMIK5-M3TIH-QR5OI-I3OAO-U7BDN',
              output: 'jsonp'
            },
            dataType: 'jsonp',
            success: function(res) {
              console.log(res)
              const info = res.result.ad_info
              const locations = res.result.location
              localStorage.setItem('longitude', locations.lng)
              localStorage.setItem('latitude', locations.lat)
              localStorage.setItem('city', info.city)
              localStorage.setItem('district', info.district)
              vm.listQuery.lat = locations.lat
              vm.listQuery.lng = locations.lng
              vm.initInfo()
              vm.getBanner()
            }
          })
        }
      })
    },
    /**
     * 获取用户信息
     * 2021-08-16 16:42:51
     * @author SnowRock
     */
    async getUserInfo() {
      await getusernew().then(res => {
        const { code, data } = res
        if (code === 200) {
          const { mobile, reCode } = data
          setLocal('recode', reCode)
          setLocal('mobile', mobile)
        }
      })
    },
    isClose(t) {
      this.topclose = t
      this.topHeight = '60px'
    },
    /**
     * 处理筛选信息
     * 2021-08-16 16:30:11
     * @author SnowRock
     */
    handleShare() {
      const title = '全国10000+加油站接入，下单立减，边省边赚，让你到爱车“吃饱饭"'
      const desc = '我在悦淘，绝对适合你，速来围观～'
      const link = 'https://web.yuetao.vip/refueling-list'
      const img = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-16/19/yuelvhuiOHhTarxh8s1605525102.png'
      wxConfigInit({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img // 分享图标
      })
    },
    /**
     * 处理tab改变
     * 2021-08-15 15:33:46
     * @author SnowRock
     */
    handleTabChange() {
      const { active } = this
      if (active === 0) {
        this.listQuery.oilId = '92'
      }
      if (active === 1) {
        this.listQuery.oilId = 2
      }
      this.fuelNumber = []
      this.list = []
      this.$forceUpdate()
      this.initInfo()
    },
    /**
     * 初始化数据
     * 2021-08-15 10:14:22
     * @author SnowRock
     */
    initInfo() {
      const { active } = this
      if (active === 0) {
        this.getComeOnList()
      }
      if (active === 1) {
        this.getGroupRefuelingList()
      }

      this.$forceUpdate()
    },
    /**
     * 获取团油列表
     * 2021-08-15 10:16:00
     * @author SnowRock
     */
    async getGroupRefuelingList() {
      try {
        const { listQuery: { oilName, sort, lng, lat }} = this
        let { page } = this.listQuery
        this.isLoading = true
        const phone = localStorage.getItem('mobile')
        const mid = localStorage.getItem('uid')
        const res = await fetchGroupRefuelingList({
          gasName: oilName,
          sort,
          lng,
          lat,
          phone,
          mid,
          page
        })
        const { code, data, msg } = res
        if (Number(code) === 200) {
          const { fuelNumber, list, query, sort, totalPage } = data
          this.fuelNumber = fuelNumber // 油号
          if (page === 1) {
            this.list = list // 油站列表
          } else {
            this.list = this.list.concat(list) // 油站列表
          }

          // 配置上拉加载是否终止
          this.isRefreshing = false
          this.isLoading = false
          this.isFinished = page >= totalPage
          if (query) {
            this.query = query.type // 配置设置
          }
          this.sorts = sort // 排序规则
        } else {
          if (page > 1) {
            page--
          }
          this.isLoading = false
          Toast(msg)
        }
      } catch (e) {
        console.log('fetchGroupRefuelingList', e)
      }
    },
    /**
     * 获取banner
     * 2021-08-13 17:02:07
     * @author SnowRock
     */
    getBanner() {
      fetchBanner({
        positionType: 'xjjy',
        uid: localStorage.getItem('mid')
      }).then(res => {
        const { code, data } = res
        if (code === 200) {
          this.banner = data
        }
      })
    },
    /**
     * 获取加油列表
     * 2021-08-13 16:21:03
     * @author SnowRock
     */
    getComeOnList() {
      let { page } = this.listQuery
      this.isLoading = true
      getXJComeOnList(this.listQuery).then(res => {
        const { code, data, msg } = res
        if (Number(code) === 200) {
          const { fuelNumber, list, query, sort, totalPage } = data
          this.fuelNumber = fuelNumber // 油号
          if (page === 1) {
            this.list = list // 油站列表
          } else {
            this.list = this.list.concat(list) // 油站列表
          }

          // 配置上拉加载是否终止
          this.isRefreshing = false
          this.isLoading = false
          this.isFinished = page >= totalPage
          this.query = query.type // 配置设置
          this.sorts = sort // 排序规则
        } else {
          if (page > 1) {
            page--
          }
          this.isLoading = false
          Toast(msg)
        }
      })
    },
    /**
     * 处理筛选
     * 2021-08-13 16:04:27
     * @author SnowRock
     */
    handleFilter() {
      this.listQuery.page = 1
      this.initInfo()
    },
    /**
     * 处理油站信息
     * 2021-08-13 16:36:46
     * @author SnowRock
     * @param params
     */
    handleNavStationDetail(params) {
      const { refuelingType, listQuery: { oilName, oilId, oilType }} = this
      const { gasId, distance } = params
      this.$router.push({
        path: '/refueling-confirm-detail',
        query: {
          gasId: gasId,
          oilName: oilName,
          oilId: oilId,
          distance: distance,
          refuelingType: refuelingType, // 油类型 类型 xiaoju 小桔加油；group 团油
          type: oilType// 燃油类型
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.app-container {
  background-color: #F8F8F8;
  min-height: 100vh;

  .banner-container {
    padding: 0 12px;
  }

  .tips-container {
    display: flex;
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;

    .tips {
     color: #999999;
    }

    .help {
      color: #333333;
    }
  }
}
</style>
